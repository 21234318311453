import React from "react"

const Content = ({ content }) => {
  return content.map((item, idx) => {
    return item.children[0].text !== "" ? (
      <>
        {item.children[0]._type === "span" ? (
          <p className="mb-3" key={`content-item-${idx}`}>
            {item.children[0].text}
          </p>
        ) : null}
        {item.children[0]._type === "list" ? (
          <p key={`content-item-${idx}`}>{item.children[0].text}</p>
        ) : null}
      </>
    ) : null
  })
}

export default Content
