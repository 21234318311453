import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet"
import Content from "../components/content"
import { SRLWrapper } from "simple-react-lightbox"

export const query = graphql`
  query ProjectData($slug: String!) {
    project: sanityProjects(slug: { current: { eq: $slug } }) {
      title
      _rawDescription
      galleryImages {
        asset {
          url
        }
      }
    }
  }
`

const SingleProjectTemplate = props => {
  const { data } = props
  const project = data && data.project

  return (
    <Layout>
      <SEO title={`${project.title}`} />
      <Helmet
        bodyAttributes={{
          class: "bg-power-lines",
        }}
      />
      <div className="py-24 flex flex-wrap container mx-auto">
        <div className="w-full text-right text-sm pb-6 pr-4 md:pr-0">
          <Link to="/projects">&laquo; View All Projects</Link>
        </div>
        <div className="flex flex-wrap w-full">
          <div className="w-full md:w-1/2 order-last md:order-first">
            <SRLWrapper>
              <div className="flex flex-wrap md:-mx-2 px-4 md:px-0 ">
                {project.galleryImages.map((img, idx) => {
                  return (
                    <div className="bg-red-500 my-2 md:m-2">
                      <a
                        href={`${img.asset.url}?w=1440&h=1024&fit=crop`}
                        data-attribute="SRL"
                        className="duotone-thumb block md:inline"
                      >
                        <img
                          src={`${img.asset.url}?w=250&h=250&fit=crop&sat=-100`}
                          alt={`${project.title} ${idx}`}
                        />
                      </a>
                    </div>
                  )
                })}
              </div>
            </SRLWrapper>
          </div>
          <div className="w-full md:w-1/2 px-4 md:px-0">
            <div className="border-b border-gray-800 mb-8 pb-8">
              <h1 className="uppercase font-bold tracking-wide mb-4 text-5xl">
                {project.title}
              </h1>
            </div>
            <article className="text-xl">
              <Content content={project._rawDescription} />
            </article>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SingleProjectTemplate
